.input {
    margin-top: 35px;
    border: none;
    border-bottom: 2px solid #403e46;
    color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    font-size: 18px;
    font-family: Roboto, sans-serif;
    width: 50%;
    background-color: #282c34;
}

.not-resizable {
    resize: none;
}

textarea {
    height: 200px;
}

.button {
    border-radius: 25px;
    color: white;
    background-color: #2f72ff;
    font-weight: bold;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px 45px;
    transition: 0.1s ease-in-out;
}

.submission {
    display: flex;
    align-items: center;
    margin-top: 35px;
    width: 100%;
    justify-content: center;
}
