.AppWrapper {
    display: flex;
    flex-direction: column;
}

.Selection {
    font-size: 16px;
    text-align: left;
    margin-bottom: 20px;
    width: 100%;
}

.Selection label {
    padding-left: 15px;
    font-weight: 700;
    line-height: 25px;
    padding-bottom: 5px;
}

.TextInput {
    width: 100%;
    border-radius: 20px;
    padding: 8px;
    font-size: 19px;
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    visibility: visible;
    color: hsl(0, 0%, 20%);
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1/1/2/3;
    grid-template-columns: 0 min-content;
    box-sizing: border-box;
    line-height: 30px;
    border: none;
}

.Hint {
    margin-right: 12px;
    float: right;
    font-size: 12px;
}

.flex-parent-element {
    display: flex;
    width: 100%;
}

.flex-child-element {
    flex: 1;
    margin: 10px;
}

.flex-child-element:first-child {
    margin-right: 20px;
}

.Icon {
    font-size: 350px;
}

.NumberOfDocuments {
    font-size: 10px;
}

.TableHeader {
    font-size: 14px;
    font-weight: bold;
}

.Plot {
    width: 500px;
    margin: 0 auto;
    margin-bottom: 5%;
}

.recharts-polar-angle-axis-tick-value {
    fill: white;
    font-size: 5px;
    /*transform: scale(1.05) translate(-10px, -20px);*/
}

.ColorPicker {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100px;
    height: 100px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    margin: 0 auto;
}

.ColorPicker::-webkit-color-swatch {
    border-radius: 15px;
    border: none;
}
