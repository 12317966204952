.AA {
    width: 700px;
    margin-top: -150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AA h3 {
    margin-bottom: 10px;
}

.AA p {
    text-align: center;
    font-size: 16px;
    padding: 0 50px;
    margin-bottom: 40px;
}

.AA-Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1b1922;
    margin-bottom: 5px;
    border-radius: 10px;
    color: white;
    border: none;
    cursor: pointer;
    padding: 12px 20px;
    transition: all ease-in-out 0.1s;
}

.AA-Row:hover,
.AA-Row:focus {
    outline: none;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.8);
    background-color: hsla(0, 0%, 100%, 0.05);
}

.AA-Row-active {
    background-color: #2c2b33;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.8);
}

.AA-Row-Name {
    font-size: 14px;
    font-weight: 700;
}

.AA-Row-in-progress {
    background: linear-gradient(160deg, #1b1922, #5f6368, #1b1922);
    background-size: 200% 200%;
    -webkit-animation: Animation 5s ease infinite;
    -moz-animation: Animation 5s ease infinite;
    animation: Animation 5s ease infinite;
}

@-webkit-keyframes Animation {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}

@-moz-keyframes Animation {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}

@keyframes Animation {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}
