.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    padding-top: 50px;
    color: white;
}

.Title {
    font-size: 90px;
    letter-spacing: 15px;
    margin: 20px;
    font-weight: 300;
}

.Search {
    min-width: 550px;
    display: flex;
    margin: 0 100px 30px;
    position: relative;
    transition: all ease-in-out 200ms;
}

.SearchArea-enter-done {
    position: relative;
}

.SearchArea-enter-active {
    position: relative;
    transition: all ease-in-out 200ms;
}

.SearchArea-exit-done {
    transform: translate(0, calc(-140px));
}

.SearchArea-exit-active {
    transform: translate(0, calc(-140px));
}

.SearchArea-exit-done .SearchHint {
    opacity: 0;
}

.SearchArea-exit-active .SearchHint {
    opacity: 0;
}

.TitleArea-enter-done {
    opacity: 1;
}

.TitleArea-enter-active {
    opacity: 1;
}

.TitleArea-exit-done {
    opacity: 0;
}

.TitleArea-exit-active {
    opacity: 0;
}

.Input {
    height: 34px;
    background-color: transparent;
    border-radius: 24px;
    padding: 3px 25px;
    border: 1px solid #5f6368;
    font-size: 16px;
    flex: 1;
    color: white;
    transition: 0.1s ease-in-out;
    word-wrap: break-word;
}

.Input:focus,
.Input:hover {
    background-color: #303134;
    outline: none;
    border: none;
    box-shadow: 0 1px 6px 0 #171717;
}

.SearchHint {
    position: absolute;
    bottom: -20px;
    right: 20px;
    font-size: 13px;
    color: #979797;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.AppsNames {
    margin-top: 50px;
    display: flex;
    width: 800px;
    justify-content: center;
    flex-direction: column;
}

.AppRow {
    display: flex;
    flex: 1;
    justify-content: stretch;
    margin-bottom: 35px;
}

.AppName {
    font-size: 20px;
    flex: 1;
    cursor: pointer;
}

.Bold {
    font-weight: 800;
}

.LoginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LoginForm input {
    margin: 5px;
    height: 30px;
    width: 100%;
    padding: 8px 25px;
}

.LoginForm button {
    margin-top: 20px;
}

.Button {
    border-radius: 25px;
    color: white;
    background-color: #2f72ff;
    font-weight: bold;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px 45px;
    transition: 0.1s ease-in-out;
}

.Loading {
    pointer-events: none; /* Disable pointer events */
    opacity: 0.7; /* Reduce opacity to indicate the button is disabled */
}

.Button:hover,
.Button:focus {
    box-shadow: 0 1px 6px 0 #171717;
    background-color: #1460ff;
}

.LogoutPanel {
    position: absolute;
    left: 20px;
    top: 50px;
}

.UserPanel {
    position: absolute;
    color: #979797;
    left: 20px;
    top: 12px;
    padding: 5px 20px;
    height: 20px;
}

.OrgPanel {
    position: absolute;
    color: #979797;
    right: 20px;
    top: 12px;
    background-color: #403e46;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.01);
    padding: 5px 20px;
    cursor: pointer;
    border-radius: 16px;
    height: 34px;
    transition: height 0.2s ease-in-out;
}

.OrgPanel:hover {
    height: 60px;
    color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.4);
}

.OrgPanelLogout {
    height: 0;
    transition: opacity 0.1s ease-out 0.1s;
    opacity: 0;
}

.OrgPanel:hover .OrgPanelLogout {
    height: auto;
    opacity: 1;
}

.Loader {
    animation: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.AppDocumentation {
    text-decoration: none;
    color: white;
    font-size: 20px;
    flex: 1;
    cursor: pointer;
}
